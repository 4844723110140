import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, BrowserAuthError, CacheLookupPolicy } from "@azure/msal-browser";
import { protectedResources } from "../authConfig";
const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api`;

export default function useApiHelper(
  config = { apiUrl: undefined, headers: {}, options: {} }
) {
  const baseUrl = config.apiUrl || apiUrl;
  const { instance, accounts } = useMsal();

  const request = {
    scopes: protectedResources.api.scopes,
    account: accounts[0] || {},
    forceRefresh: false,
    cacheLookupPolicy: CacheLookupPolicy.Default
  };

  const _getBearer = async () => {
    try {
      const tknResponse = await instance.acquireTokenSilent(request).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return instance.acquireTokenPopup(request);
        } else if (error instanceof BrowserAuthError) {
          localStorage.clear();
          window.location.replace("/login");
        }
      })
      return { Authorization: `Bearer ${tknResponse.accessToken}` };
    } catch (e) {
      console.error(e);
    }
  };


  const _getReqOpts = async () => {
    const reqOpts = {
      headers: {
        "Content-Type": "application/json",
        ...(await _getBearer()),
        ...config.headers,
      },
      mode: "cors",
      ...config.options,
    };
    
    //If we have specified no content type, remove the header (for formData
    if (config.headers["Content-Type"] === null) {
      delete reqOpts.headers["Content-Type"];
    }

    return reqOpts;
  };

  async function _handleResponse(res) {
    if (!res.ok) {
      let data = await res.json();
      
      if (data && data.message) {
        throw new Error(data.message);
      } else {
        throw new Error(`HTTP Error: ${res.status}`);
      }
    }

    if (res.headers.get("content-type").includes("application/json")) {
      return await res.json();
    } else {
      return res;
    }
  }

  async function get(path) {
    let res = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      method: "GET",
    });

    return await _handleResponse(res);
  }

  async function put(path, body) {
    let res = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: body,
      method: "PUT",
    });

    return await _handleResponse(res);
  }

  async function patch(path, body) {
    let res = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: body,
      method: "PATCH",
    });

    return await _handleResponse(res);
  }

  async function post(path, body) {
    let res = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: body,
      method: "POST",
    });

    return await _handleResponse(res);
  }

  async function del(path, body) {
    let res = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      method: "DELETE",
      body: body ? body : undefined,
    });

    return await _handleResponse(res);
  }

  return {
    get,
    put,
    patch,
    post,
    del,
  };
}
