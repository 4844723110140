import { useState, useRef, useEffect } from "react";
import LoadingWheel from "./LoadingWheel"

export default function SubmitButton({ submitting, text }) {
    
    const [buttonWidth, setButtonWidth] = useState(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        if (buttonRef.current && buttonWidth === null) {
            const width = buttonRef?.current?.getBoundingClientRect()?.width;
            if(width) {
                setButtonWidth(`${width}px`);
            }
        }
    }, []);

    return (
        <button
            ref={buttonRef}
            type="submit"
            className="p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault h-[42px] flex items-center justify-center"
            style={{ width: buttonWidth || "auto" }}
            disabled={submitting}
        >
            {submitting 
                ? <div className="flex items-center justify-center w-6 h-6">{<LoadingWheel />}</div> 
                : text
            }
        </button>
    )
}
