import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useCurrentUserContext from "../contexts/UserContext";

export function usePermissions(customerId, queryParams = {}) {
    const {
        currentUser: { actAs },
    } = useCurrentUserContext();
    const { patch } = useApiHelper({
        headers: { "X-CustomerId": customerId, "X-ActAs": actAs },
    });

    const queryClient = useQueryClient();

    const createPermissions = useMutation(
        (permissionsReq) => patch("/Admin/updateUser", JSON.stringify(permissionsReq)),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["admin", "updateUser"]);
          }
        }
    );

    return {
      createPermissions
  };
}