import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import useQSBuilder from "../hooks/useQSBuilder";
import useCurrentUserContext from "../contexts/UserContext";

export function useEquipment(
  customerId,
  queryParams = {
    countOnly: false,
    includeJobs: false,
    includeTonerParts: false,
    inclueOrders: false,
    onlyCopiers: false,
  }
) {
  const { currentUser: { actAs } } = useCurrentUserContext();
  const { get, post } = useApiHelper({
    headers: { "X-CustomerId": customerId, "X-ActAs": actAs  },
  });
  const { buildQS } = useQSBuilder();
  const queryClient = useQueryClient();

  const equipment = useQuery({
    queryKey: ["equipment", customerId, queryParams],
    queryFn: () => get(`/equipment${buildQS(queryParams)}`),
  });

  const requestConsumables = useMutation(
    (tonerRequest) => post("/consumable/order", JSON.stringify(tonerRequest)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("equipment");
      },
      onError: (error) => {
        console.error("Mutation error:", error.message);
      },
    }
  );

  const submitReadings = useMutation((readingsRequest) =>
    post("/consumable/meters", JSON.stringify(readingsRequest))
  );

  return {
    equipment,
    requestConsumables,
    submitReadings,
  };
}
