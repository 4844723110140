export function regexValidation(key, value) {
    const regexObj = {
      specialCharacters: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
      emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      containsAtSymbol: /@/,
      postcodePattern: /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
      alphaSpaceSpecialPattern: /^[a-zA-Z\s &-/]*$/,
      alphaSpacePattern: /^[a-zA-Z\s]*$/,
      namePattern: /^[a-zA-Z '.-]*$/,
      alphaPattern: /^[a-zA-Z]*$/,
      alphaNumericPattern: /^[a-zA-Z0-9]*$/,
      alphaNumericSpacePattern: /^[a-zA-Z0-9\s]*$/,
      alphaNumericSpaceSpecialPattern: /^[a-zA-Z0-9\s -.]*$/,
      numericPattern: /^[0-9]*$/,
      numericSpecialPattern: /^[0-9 ,.]*$/,
      telephonePattern: /^\+?[0-9\s()-]*$/,
      mobilePattern: /^[0-9\s]*$/,
      currencyPattern: /^(?=(?:,?\d){1,99}(?:\.|$))\d{1,3}(?:,\d{3})*(?:\.\d{2,10})?$/,
      numberOnlyPattern: /^[0-9]*$/,
      yyyymmdd: /^(?:(?:(?:(?:(?:[1-9]\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:[2468][048]|[13579][26])00))(\/|-|\.)(?:0?2\1(?:29)))|(?:(?:[1-9]\d{3})(\/|-|\.)(?:(?:(?:0?[13578]|1[02])\2(?:31))|(?:(?:0?[13-9]|1[0-2])\2(?:29|30))|(?:(?:0?[1-9])|(?:1[0-2]))\2(?:0?[1-9]|1\d|2[0-8])))))$/,
      ddmmyyyy: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
    }
    if(!key || !value) return false;
    return regexObj[key].test(value);
}