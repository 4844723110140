import { useState } from "react";
import { useForm } from "react-hook-form";
import { regexValidation  } from "../../utils/validationUtils";

import {
  permissionsCategories,
  permissionsLookup,
} from "../../hooks/useRoleCheck";
import useNotification from "../../hooks/useNotifications";
import useCurrentUserContext from "../../contexts/UserContext";
import SitePermissions from "./SitePermissions";
import { useAdmin } from "../../data/useAdmin";
import SiteListRow from "./SiteListRow";
import LoadingWheel from "../shared/LoadingWheel";
import SubmitButton from "../shared/SubmitButton";

function getGlobalPermissionsTemplate(sites, permissionsLookup) {
  let permObject = {
    customerId: sites[0].id,
  };

  Object.values(permissionsLookup).forEach(
    (perm) => (permObject[perm.realm] = false)
  );

  return permObject;
}

function getUserPermissions(sites, permissionsLookup) {
  const permValues = Object.values(permissionsLookup);
  let sitePermissions = sites.map((site) => ({
    customerId: site.id,
  }));

  sitePermissions.forEach((site) =>
    permValues.forEach((perm) => (site[perm.realm] = false))
  );

  return sitePermissions;
}

export default function UserInvite({ customer, setShowInvite, refetch }) {
  const { currentUser } = useCurrentUserContext();
  const { addNotification } = useNotification();
  const { inviteUser } = useAdmin(customer.id);
  const sites = currentUser.user.customers;
  const [userPermissions, setUserPermissions] = useState(
    getUserPermissions(sites, permissionsLookup)
  );
  const [globalPermissionTemplate, setGlobalPermissionsTemplate] = useState(
    getGlobalPermissionsTemplate(sites, permissionsLookup)
  );
  const [permissionsType, setPermissionsType] = useState(0);
  const [selectedSites, setSelectedSites] = useState({});
  const [expanded, setExpanded] = useState({});
  const [render, setRender] = useState(false); 
  const [formSubmitting, setFormSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const updateGlobalPermissions = (realm, value) => {
    setGlobalPermissionsTemplate((old) => {
      old[realm] = value;
      return { ...old };
    });
  };

  const onSubmit = (data) => {
    let sitePermissions;
    let finalPermissions;

    if (permissionsType === 0) {
      finalPermissions = userPermissions.map((userPerm, i) => ({
        ...globalPermissionTemplate,
        customerId: userPerm.customerId,
        isActive: true
      }));

      sitePermissions = finalPermissions;
    } else if (permissionsType === 1) {

      const selected = Object.keys(selectedSites)
        .filter(x => Boolean(selectedSites[x]))
        .map(x => Number(x));;

      const selectedSitePermissions = userPermissions
        .filter(permission => selected.includes(Number(permission.customerId)))
        .map((userPerm, i) => ({
          ...globalPermissionTemplate,
          customerId: userPerm.customerId,
          isActive: true
        }));

      const unselectedSitePermissions = userPermissions
        .filter(permission => !selected.includes(Number(permission.customerId)))
        .map((userPerm, i) => ({
          ...globalPermissionTemplate,
          customerId: userPerm.customerId,
          isActive: false
        }));

      finalPermissions = [...selectedSitePermissions, ...unselectedSitePermissions];

      sitePermissions = finalPermissions;
    }
    else if (permissionsType === 2) {
      sitePermissions = userPermissions.map(permission => {
        const { customerId, isActive, ...rest } = permission;
        const allFalseExceptIsActive = Object.values(rest).every(value => value === false);
        return {
          customerId,
          isActive: allFalseExceptIsActive ? false : isActive,
          ...rest
        };
      });
    }

    let newData = {
      Department: data.Department,
      EmailAddress: data.EmailAddress,
      FirstName: data.FirstName,
      LastName: data.LastName,
      isAdmin: Boolean(data.IsAdmin),
      sitePermissions: sitePermissions,
    };

    setFormSubmitting(true);

    inviteUser.mutate(newData, {
      onSuccess: (res) => {
        addNotification({
          variant: "success",
          primaryText: "Invite sent",
          secondaryText: "Your invite has been sent",
        });
        setUserPermissions(JSON.parse(res.result));
        setGlobalPermissionsTemplate(getGlobalPermissionsTemplate(sites, permissionsLookup));
        setSelectedSites([]);
        reset();
        setFormSubmitting(false);
      },
      onError: (data) => {
        addNotification({
          variant: "error",
          primaryText: "Failed to send invite",
          secondaryText: data.message || "Your invite failed to send",
        });
        setFormSubmitting(false);
      },
    });
  };

  return (
    <section aria-labelledby="user-details-heading">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="shadow sm:overflow-hidden">
          <div className="bg-gray-50 p-2 text-right sm:px-6 space-x-4">
            <button
              onClick={() => setShowInvite(false)}
              className="p-2 px-4 rounded-full border bg-white text-ag-default border-ag-default"
            >
              Back
            </button>
          </div>
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="user-details-heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Invite a user
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Fill out the form below to invite a new user to the portal
              </p>
            </div>

            <div className="mt-6 grid grid-cols-4 gap-6">
              <div className="col-span-4 md:col-span-2">
                <label className="mb-1 font-medium text-ag-default">
                  First Name
                </label>
                <input
                  type="text"
                  {...register("FirstName", {
                    required: "Please enter a first name",
                    minLength: {
                      value: 2,
                      message: "Please enter a valid name with 2 or more characters",
                    },
                    validate: (value) => {
                      if (regexValidation("emailPattern", value)) {
                        return "Names cannot be email addresses";
                      }
                      if (regexValidation("containsAtSymbol", value)) {
                        return "Names cannot contain '@' symbol";
                      }
                      return true;
                    }
                  })}
                  aria-invalid={errors.FirstName ? "true" : "false"}
                  className={`w-full border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.FirstName && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.FirstName && (
                  <p role="alert" className="text-red-600">
                    {errors.FirstName?.message}
                  </p>
                )}
              </div>

              <div className="col-span-4 md:col-span-2">
                <label className="mb-1 font-medium text-ag-default">
                  Last Name
                </label>
                <input
                  type="text"
                  {...register("LastName", {
                    required: "Please enter your last name",
                    minLength: {
                      value: 2,
                      message: "Please enter a valid name with 2 or more characters",
                    },
                    validate: (value) => {
                      if (regexValidation("emailPattern", value)) {
                        return "Names cannot be email addresses";
                      }
                      if (regexValidation("containsAtSymbol", value)) {
                        return "Names cannot contain '@' symbol";
                      }
                      return true;
                    }
                  })}
                  aria-invalid={errors.LastName ? "true" : "false"}
                  className={`w-full border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.LastName && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.LastName && (
                  <p role="alert" className="text-red-600">
                    {errors.LastName?.message}
                  </p>
                )}
              </div>

              <div className="col-span-4 md:col-span-2">
                <label className="mb-1 font-medium text-ag-default">
                  Email Address
                </label>
                <input
                  type="text"
                  {...register("EmailAddress", {
                    required: "Please enter your email address",
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address"
                    }
                  })}
                  aria-invalid={errors.EmailAddress ? "true" : "false"}
                  className={`w-full border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.EmailAddress && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.EmailAddress && (
                  <p role="alert" className="text-red-600">
                    {errors.EmailAddress?.message}
                  </p>
                )}
              </div>

              <div className="col-span-4 md:col-span-2">
                <label className="mb-1 font-medium text-ag-default">
                  Department
                </label>
                <input
                  type="text"
                  {...register("Department", {
                    minLength: {
                      value: 2,
                      message: "Please enter a valid department with 2 or more characters",
                    },
                  })}
                  aria-invalid={errors.Department ? "true" : "false"}
                  className={`w-full border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                    errors.Department && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                  }`}
                />
                {errors.Department && (
                  <p role="alert" className="text-red-600">
                    {errors.Department?.message}
                  </p>
                )}
              </div>

              <div className="col-span-4 flex flex-col">
                <label className="mb-1 first-line:font-medium text-ag-default">
                  User Role
                </label>
                <select
                  {...register("IsAdmin", { value: "0" })}
                  className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
                >
                  <option value={1}>Admin</option>
                  <option value={0}>User</option>
                </select>
              </div>
            </div>
                <div className="mt-5 flex flex-col">
                  <div className="my-5">
                    <h2
                      id="user-heading"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Permissions
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      Select which sections of the portal this user can access.
                    </p>
                  </div>
                  <fieldset className="mb-6">
                        <legend className="sr-only">Permission Type</legend>
                        <div className="space-y-4">
                          <div className="flex items-center">
                            <input
                              id="0"
                              checked={permissionsType === 0}
                              onChange={() => setPermissionsType(0)}
                              name="permission-type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-ag-default focus:ring-0"
                            />
                            <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              Same permissions across all sites
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="1"
                              checked={permissionsType === 1}
                              onChange={() => setPermissionsType(1)}
                              name="permission-type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-ag-default focus:ring-0"
                            />
                            <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              Same permissions across selected sites
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="2"
                              checked={permissionsType === 2}
                              onChange={() => setPermissionsType(2)}
                              name="permission-type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-ag-default focus:ring-0"
                            />
                            <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              Different permissions for different sites
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      {
                        {
                          0: (
                            <div className="py-4 px-6 grid grid-cols-2 gap-y-4">
                              {Object.keys(permissionsCategories).map(
                                (catKey, i) => {
                                  let category = permissionsCategories[catKey];
                                  let permissions = Object.values(
                                    permissionsLookup
                                  ).filter((perm) => perm.category === catKey);

                                  return (
                                    <div>
                                      <span className="font-medium text-sm text-gray-600">
                                        {category.name}
                                      </span>
                                      {permissions.map((permission) => (
                                        <div className="flex items-center space-x-2">
                                          <input
                                            type="checkbox"
                                            className="h-4 w-4 rounded text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
                                            onChange={() =>
                                              updateGlobalPermissions(
                                                permission.realm,
                                                !globalPermissionTemplate[
                                                  permission.realm
                                                ]
                                              )
                                            }
                                            checked={
                                              globalPermissionTemplate[
                                                permission.realm
                                              ]
                                            }
                                          />
                                          <span className="text-sm text-gray-600">
                                            {permission.name}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ),
                          1: (
                            <>
                              <div className="mb-6 py-4 px-6 grid grid-cols-2 gap-y-4">
                                {Object.keys(permissionsCategories).map(
                                  (catKey, i) => {
                                    let category = permissionsCategories[catKey];
                                    let permissions = Object.values(
                                      permissionsLookup
                                    ).filter((perm) => perm.category === catKey);

                                    return (
                                      <div>
                                        <span className="font-medium text-sm text-gray-600">
                                          {category.name}
                                        </span>
                                        {permissions.map((permission) => (
                                          <div className="flex items-center space-x-2">
                                            <input
                                              type="checkbox"
                                              className="h-4 w-4 rounded text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
                                              onChange={() =>
                                                updateGlobalPermissions(
                                                  permission.realm,
                                                  !globalPermissionTemplate[
                                                    permission.realm
                                                  ]
                                                )
                                              }
                                              checked={
                                                globalPermissionTemplate[
                                                  permission.realm
                                                ]
                                              }
                                            />
                                            <span className="text-sm text-gray-600">
                                              {permission.name}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              {sites
                                .sort((a) => (a.customerGroup ? -1 : 1))
                                .map((site, i) => {
                                  return (
                                    <SiteListRow
                                      key={`site-${i}`}
                                      site={site}
                                      selectedSites={selectedSites}
                                      setSelectedSites={setSelectedSites}
                                      index={i}
                                    />
                                  );
                                })}
                            </>
                          ),
                          2: sites
                            .sort((a) => (a.customerGroup ? -1 : 1))
                            .map((site, i) => {
                              const sitePermissions = userPermissions.find(
                                (sp) => Number(sp.customerId) === Number(site.id)
                              );
                              return (
                                <SitePermissions
                                  key={`site-${i}`}
                                  site={site}
                                  onClick={() => setRender(!render)}
                                  expanded={expanded}
                                  setExpanded={setExpanded}
                                  userPermissions={userPermissions}
                                  setUserPermissions={setUserPermissions}
                                  sitePermissions={sitePermissions}
                                  index={i}
                                />
                              );
                            }),
                        }[permissionsType]
                      }
                </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 space-x-4">
          <SubmitButton text="Invite" submitting={formSubmitting} />
        </div>
      </form>
    </section>
  );
}
