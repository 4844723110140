import { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { regexValidation  } from "../../utils/validationUtils";

import {
  InformationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import LoadingWheel from "../../components/shared/LoadingWheel";

export function AcceptInvite() {
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/invite`;
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [invite, setInvite] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(false);
  const [formError, setFormError] = useState(undefined);
  const [passwordCriteriaMet, setPasswordCriteriaMet] = useState({
    uppercase: false,
    lowercase: false,
    numeric: false,
    special: false,
    length: false,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch
  } = useForm();

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])[A-Za-z\d@#$%^&*()-_+=[\]{}|\\:',?/`~"();!.]{8,16}$/;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      let result = await fetch(`${apiUrl}/${invite.inviteID}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!result.ok) {
        throw new Error("Response code does not indicate success");
      } else {
        let data = await result.json();
        if (!data.err) {
          setSuccess(true);
        } else {
          throw new Error(`API Error: ${data.message}`);
        }
      }
    } catch (e) {
      console.error(e.message);
      setError(
        "An error has occoured while attempting to create the user account. Please try again, if the issue persists please contact support"
      );
    } finally {
      setLoading(false);
    }
  };
  
  const getInvite = useCallback(
    async (inviteId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/invite/${inviteId}`
        );

        if (!result.ok) {
          throw new Error("Response code did not indicate success.");
        } else {
          let data = await result.json();
          setInvite(data);
          reset({
            FirstName: data.firstName,
            LastName: data.lastName,
            EmailAddress: data.emailAddress,
          });
        }
      } catch (e) {
        console.error(e.message);
        setError(
          "Error getting invite details. Please try again, if this issue persists please contact support"
        );
      } finally {
        setLoading(false);
      }
    },
    [reset]
  );

  const validatePassword = value => {
    if (!value) {
      return 'Password is required';
    }

    const allowedSpecialChars = /[@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]/;
    const criteriaMetUpdate = {
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      numeric: /\d/.test(value),
      //This checks that the string has special characters and that these are all valid and allowed by b2c
      special: allowedSpecialChars.test(value) && /^[A-Za-z\d@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]*$/.test(value),
      length: value.length >= 8 && value.length <= 16,
    };
  
    // Ensures at least three out of the four criteria are met
    const metCriteriaCount = ['uppercase', 'lowercase', 'numeric', 'special'].reduce(
      (count, key) => count + (criteriaMetUpdate[key] ? 1 : 0),
      0
    );
  
    criteriaMetUpdate.valid = criteriaMetUpdate.length && criteriaMetUpdate.special && metCriteriaCount >= 3;


    setPasswordCriteriaMet(criteriaMetUpdate);
  
    if (!criteriaMetUpdate.valid) {
      return 'Password must meet the criteria';
    }
    return true;
  };

  const handlePasswordChange = event => {
    validatePassword(event.target.value);
  };

  useEffect(() => {
    if (searchParams.has("token")) {
      let token = searchParams.get("token");
      getInvite(token);
    } else {
      setError("Invite token not found. Please try again, if this issue persists please contact support.");
      setLoading(false);
    }
  }, [searchParams, setError, getInvite]);

  if (loading) {
    return (
      <div className="bg-white rounded shadow p-6">
        <LoadingWheel />
      </div>
    );
  } else if (error) {
    return (
      <div className="bg-white rounded shadow px-6 py-4">
        <div className="rounded-md bg-red-50 p-4 w-full">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (success) {
    return (
      <div className="bg-white rounded shadow px-6 py-4">
        <div className="mb-6 rounded-md bg-green-50 p-4 w-full">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-green-700">
                Invite successfully accepted and user account created. You can
                now log in.
              </p>
            </div>
          </div>
        </div>

        <a
          href="/"
          className="p-1.5 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
        >
          Go to Login
        </a>
      </div>
    );
  } else {
    return (
      <div className="bg-white rounded shadow p-6">
        <h1 className="text-xl font-medium text-ag-default mb-4">
          Your Details
        </h1>
        <p className="text-gray-500">
          Please complete the form below to complete your invite to the Agilico
          customer portal
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-gray-500 md:w-1/2"
        >
          <div className="mt-5 grid grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                First Name
              </label>
              <input
                type="text"
                {...register("FirstName", {
                  required: "Please enter your name",
                  minLength: {
                    value: 2,
                    message: "Please enter a valid name with 2 or more characters",
                  },
                  validate: (value) => {
                    if (regexValidation("emailPattern", value)) {
                      return "Names cannot be email addresses";
                    }
                    if (regexValidation("containsAtSymbol", value)) {
                      return "Names cannot contain '@' symbol";
                    }
                    return true;
                  }
                })}
                aria-invalid={errors.FirstName ? "true" : "false"}
                className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                  errors.FirstName && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                }`}
              />
              {errors.FirstName && (
                <p role="alert" className="text-red-600">
                  {errors.FirstName?.message}
                </p>
              )}
            </div>

            <div className="flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                Last Name
              </label>
              <input
                type="text"
                {...register("LastName", {
                  required: "Please enter your name",
                  minLength: {
                    value: 3,
                    message: "Please enter a valid name with 3 or more characters",
                  },
                  validate: (value) => {
                    if (regexValidation("emailPattern", value)) {
                      return "Names cannot be email addresses";
                    }
                    if (regexValidation("containsAtSymbol", value)) {
                      return "Names cannot contain '@' symbol";
                    }
                    return true;
                  }
                })}
                aria-invalid={errors.LastName ? "true" : "false"}
                className={`border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue ${
                  errors.LastName && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                }`}
              />
              {errors.LastName && (
                <p role="alert" className="text-red-600">
                  {errors.LastName?.message}
                </p>
              )}
            </div>

            <div className="flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                Your Email
              </label>
              <input
                type="text"
                disabled
                {...register("EmailAddress", {
                  required: "Please enter your email address",
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                aria-invalid={errors.EmailAddress ? "true" : "false"}
                className={`border text-black bg-gray-200 border-gray-400 p-2 focus:border-ag-darkBlue ${
                  errors.EmailAddress && "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
                }`}
              />
              {errors.EmailAddress && (
                <p role="alert" className="text-red-600">
                  {errors.EmailAddress?.message}
                </p>
              )}
            </div>

            <div className="flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                Contact Number
              </label>
              <input
                type="text"
                {...register("ContactNumber", {
                  minLength: {
                    value: 7,
                    message: "Please enter a valid contact number",
                  },
                  validate: (value) => {
                    return regexValidation("telephonePattern", value) ? true : "Please enter a valid contact number";
                  },
                })}
                className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              />
            </div>

            <div className="flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                Department
              </label>
              <input
                type="text"
                {...register("Department")}
                className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              />
            </div>

            <div />

            <div className="flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                Password
              </label>
              <input
                type="password"
                {...register("Password", { 
                  required: true,
                  validate: validatePassword 
                })}
                className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
                onChange={handlePasswordChange}
              />
              {errors.Password && errors.Password.type === "required" && <p className="text-red-600">Password is required</p>}
              {errors.Password && errors.Password.type === "validate" && <p className="text-red-600">{errors.Password.message}</p>}
              {watch('Password') !== watch('ConfirmPassword') && watch('ConfirmPassword') && <p className="text-red-600">Passwords must match</p>}

            </div>

            <div className="flex flex-col">
              <label className="mb-1 font-medium text-ag-default">
                Confirm Password
              </label>
              <input
                type="password"
                {...register("ConfirmPassword")}
                className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              />
            </div>

            <div className="flex flex-col">
              <ul className="text-gray-500 text-sm pl-5 list-disc">
                <li className={ passwordCriteriaMet.uppercase ? 'text-green-500' : 'text-gray-500' }>At least one uppercase letter</li>
                <li className={ passwordCriteriaMet.lowercase ? 'text-green-500' : 'text-gray-500' }>At least one lowercase letter</li>
                <li className={ passwordCriteriaMet.numeric ? 'text-green-500' : 'text-gray-500' }>At least one numeric character</li>
                <li className={ passwordCriteriaMet.special ? 'text-green-500' : 'text-gray-500' }>At least one valid special character</li>
                <li className={ passwordCriteriaMet.length ? 'text-green-500' : 'text-gray-500' }>8 to 16 characters long</li>
              </ul>
            </div>

            <div />
          </div>

          {formError && <p className="text-red-600 my-4">{formError}</p>}

          <button
            type="submit"
            className="mt-8 p-1.5 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
          >
            Accept Invite
          </button>
        </form>
      </div>
    );
  }
}
