import { useEffect, useState, memo } from "react";
import useNotification from "../../hooks/useNotifications";
import LoadingWheel from "../shared/LoadingWheel";
import useCurrentUserContext from "../../contexts/UserContext";
import { usePermissions } from "../../data/usePermissions";
import { useAdmin } from "../../data/useAdmin";
import SitePermissions from "./SitePermissions";
import {
  permissionsCategories,
  permissionsLookup
} from "../../hooks/useRoleCheck";
import { checkPermissionSimilarity } from "../../utils/checkPermissionSimilarity";
import SiteListRow from "./SiteListRow";

//Permission types
// 0 - Same across all
// 1 - Same across selected sites
// 2 - Different across sites
const UserEdit = memo(({ 
  user: userIn, 
  setUser: setUserIn, 
  customer, 
  refetch
}) =>
{

  const { addNotification } = useNotification();
  const { currentUser } = useCurrentUserContext();
  const { createPermissions } = usePermissions(customer.id);
  const { deleteUser } = useAdmin(customer.id);
  const [globalPermissionTemplate, setGlobalPermissionsTemplate] = useState({...userIn.sitePermissions[0]});
  const [expanded, setExpanded] = useState({});
  const [render, setRender] = useState(false);                         
  const [user, setUser] = useState(userIn);
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [userPermissions, setUserPermissions] = useState(userIn.sitePermissions);
  const [selectedSites, setSelectedSites] = useState(userIn.sitePermissions
    .filter(sitePerm => sitePerm.isActive)
    .reduce((acc, obj) => ({ ...acc, [obj.customerId]: true }), {}));
  const [permissionsType, setPermissionsType] = useState(checkPermissionSimilarity(userIn.sitePermissions));

  useEffect(() => {
    setPermissionsType(checkPermissionSimilarity(userIn.sitePermissions));
  }, [userIn.sitePermissions]);

  const sites = currentUser.user.customers;

  const updateGlobalPermissions = (realm, value) => {
    setGlobalPermissionsTemplate((old) => {
      old[realm] = value;
      return { ...old };
    });
  };

  const onSave = () => {
    setPermissionsLoading(true);
    let newData;
    let finalPermissions;

    if (permissionsType === 0) {
      finalPermissions = userPermissions.map((userPerm, i) => ({
        ...globalPermissionTemplate,
        customerId: userPerm.customerId,
        isActive: true
      }));

      newData = {
        userId: user.id,
        isAdmin: Boolean(user.isAdmin),
        permissionsType: permissionsType,
        sitePermissions: finalPermissions,
      }
    } else if (permissionsType === 1) {

      const selected = Object.keys(selectedSites)
        .filter(x => Boolean(selectedSites[x]))
        .map(x => Number(x));

      const selectedSitePermissions = userPermissions
        .filter(permission => selected.includes(Number(permission.customerId)))
        .map(x => { return { 
          ...globalPermissionTemplate,
          isActive: true,
          customerId: x.customerId,
          userId: user.id }})

      const existingFiltered = userPermissions
      .filter(permission => !selected.includes(Number(permission.customerId)))

      newData = {
        userId: user.id,
        isAdmin: Boolean(user.isAdmin),
        permissionsType: permissionsType,
        sitePermissions: [ ...selectedSitePermissions, ...existingFiltered ],
      };
    } else if (permissionsType === 2) {
        newData = {
          isAdmin: Boolean(user.isAdmin),
          userId: user.id,
          permissionsType,
          isActive: true,
          sitePermissions: userPermissions.map(x => { return { 
            ...x, 
            userId: user.id }})
        }
    }

    createPermissions.mutate(newData, {
      onSuccess: (res) => {
        setUserIn({ ...userIn, sitePermissions: JSON.parse(res.result) });
        setUserPermissions(JSON.parse(res.result));
        addNotification({
          variant: "success",
          primaryText: "User updated!",
          secondaryText: "This user has been updated successfully",
        });
        setPermissionsLoading(false);
      },
    });
  };

  const onRemove = () => {
    deleteUser.mutate(userIn, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "User removed",
          secondaryText: "User has been removed successfully",
        });
        refetch();
        setUserIn(undefined);
      },
      onError: (data) => {
        addNotification({
          variant: "error",
          primaryText: "Failed to remove user",
          secondaryText: data.message || "User has not been removed",
        });
      },
    });
  }

  return (
    <>
      <section aria-labelledby="user-heading" className="shadow sm:rounded-md">
        <div className="sm:overflow-hidden">
          <div className="bg-gray-50 p-2 text-right sm:px-6 space-x-4">
            <button
              onClick={() => setUserIn(undefined)}
              className="p-2 px-4 rounded-full border bg-white text-ag-default border-ag-default"
            >
              Back
            </button>
          </div>
          <div className="bg-white py-6 px-4 sm:p-6">
            <div className="flex items-center space-x-4">
              <div>
                <h2
                  id="user-heading"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {user.firstname} {user.lastname}
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Update {user.firstname} {user.lastname}'s permissions and
                  accounts.
                </p>
              </div>
            </div>

            <div className="divide-y divide-gray-200 mt-6">
              <div>
                <label className="font-medium text-ag-default">Actions</label>
                <div className="mt-2 grid grid-cols-2 gap-4">
                  <div>
                    <button
                      type="button"
                      className="w-full p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
                      onClick={onRemove}
                    >
                      Remove User
                    </button>
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="mb-1 mt-3 font-medium text-ag-default">
                    User Role
                  </label>
                  <select
                    value={Number(user.isAdmin)}
                    onChange={(event) => {
                      setUser((oldUser) => ({
                        ...oldUser,
                        isAdmin: parseInt(event.target.value),
                      }));
                    }}
                    className="border text-black bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
                  >
                    <option value={1}>Admin</option>
                    <option value={0}>User</option>
                  </select>
                </div>
              </div>
              <div className="mt-5 flex flex-col">
                {!Boolean(user.isAdmin) && permissionsLoading && ( 
                  <div className="my-5">
                    <LoadingWheel />
                  </div>
                )}

                {!Boolean(user.isAdmin) && !permissionsLoading && (
                  <>
                      <div className="my-5">
                        <h2
                          id="user-heading"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Permissions
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          Select which sections of the portal this user can
                          access.
                        </p>
                      </div>
                      <fieldset className="mb-6">
                        <legend className="sr-only">Permission Type</legend>
                        <div className="space-y-4">
                          <div className="flex items-center">
                            <input
                              id="0"
                              checked={permissionsType === 0}
                              onChange={() => setPermissionsType(0)}
                              name="permission-type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-ag-default focus:ring-0"
                            />
                            <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              Same permissions across all sites
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="1"
                              checked={permissionsType === 1}
                              onChange={() => setPermissionsType(1)}
                              name="permission-type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-ag-default focus:ring-0"
                            />
                            <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              Same permissions across selected sites
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="2"
                              checked={permissionsType === 2}
                              onChange={() => setPermissionsType(2)}
                              name="permission-type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-ag-default focus:ring-0"
                            />
                            <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              Different permissions for different sites
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      {
                        {
                          0: (
                            <div className="py-4 px-6 grid grid-cols-2 gap-y-4">
                              {Object.keys(permissionsCategories).map(
                                (catKey, i) => {
                                  let category = permissionsCategories[catKey];
                                  let permissions = Object
                                    .values(permissionsLookup)
                                    .filter((perm) => perm.category === catKey);
                                  return (
                                    <div key={i}>
                                      <span className="font-medium text-sm text-gray-600">
                                        {category.name}
                                      </span>
                                      {permissions.map((permission, j) => (
                                        <div key={j} className="flex items-center space-x-2">
                                          <input
                                            type="checkbox"
                                            className="h-4 w-4 rounded text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
                                            onChange={() =>
                                              updateGlobalPermissions(
                                                permission.realm,
                                                !globalPermissionTemplate[
                                                  permission.realm
                                                ]
                                              )
                                            }
                                            checked={
                                              globalPermissionTemplate[
                                                permission.realm
                                              ]
                                            }
                                          />
                                          <span className="text-sm text-gray-600">
                                            {permission.name}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ),
                          1: (
                            <>
                              <div className="mb-6 py-4 px-6 grid grid-cols-2 gap-y-4">
                                {Object.keys(permissionsCategories).map(
                                  (catKey, i) => {
                                    let category = permissionsCategories[catKey];
                                    let permissions = Object.values(
                                      permissionsLookup
                                    ).filter((perm) => perm.category === catKey);
                                    return (
                                      <div key={i}>
                                        <span className="font-medium text-sm text-gray-600">
                                          {category.name}
                                        </span>
                                        {permissions.map((permission, j) => (
                                          <div key={j} className="flex items-center space-x-2">
                                            <input
                                              type="checkbox"
                                              className="h-4 w-4 rounded text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
                                              onChange={() =>
                                                updateGlobalPermissions(
                                                  permission.realm,
                                                  !globalPermissionTemplate[
                                                    permission.realm
                                                  ]
                                                )
                                              }
                                              checked={
                                                globalPermissionTemplate[
                                                  permission.realm
                                                ]
                                              }
                                            />
                                            <span className="text-sm text-gray-600">
                                              {permission.name}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              {sites
                                .sort((a, b) => {
                                  let aParts = a.reference.split("-");
                                  let bParts = b.reference.split("-");

                                  if(!aParts[1] && bParts[1]) {
                                      return -1;
                                  } else if (!aParts[1] && !bParts[1]) {
                                      return 0;
                                  } else {
                                      let aNumber = parseInt(aParts[1], 10);
                                      let bNumber = parseInt(bParts[1], 10);
                                      return aNumber - bNumber;
                                  }
                              })
                                .map((site, i) => {
                                  return (
                                    <SiteListRow
                                      key={`site-${i}`}
                                      site={site}
                                      selectedSites={selectedSites}
                                      setSelectedSites={setSelectedSites}
                                      index={i}
                                    />
                                  );
                                })}
                            </>
                          ),
                          2: sites
                            .sort((a, b) => {
                                let aParts = a.reference.split("-");
                                let bParts = b.reference.split("-");
                            
                                if(!aParts[1] && bParts[1]) {
                                    return -1;
                                } else if (!aParts[1] && !bParts[1]) {
                                    return 0;
                                } else {
                                    let aNumber = parseInt(aParts[1], 10);
                                    let bNumber = parseInt(bParts[1], 10);
                                    return aNumber - bNumber;
                                }
                              })
                            .map((site, i) => {
                              const sitePermissions = userIn.sitePermissions.find(
                                (sp) => Number(sp.customerId) === Number(site.id)
                              );
                              return (
                                <SitePermissions
                                  key={`site-${i}`}
                                  site={site}
                                  onClick={() => setRender(!render)}
                                  expanded={expanded}
                                  setExpanded={setExpanded}
                                  userPermissions={userPermissions}
                                  setUserPermissions={setUserPermissions}
                                  sitePermissions={sitePermissions}
                                  index={i}
                                />
                              );
                            }),
                        }[permissionsType]
                      }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 space-x-4">
          <button
            onClick={(permissionsLoading) ? function() {} : onSave}
            className={`p-2 px-4 rounded-full text-white hover:bg-ag-lightDefault ${(permissionsLoading) ? 'bg-ag-gray cursor-not-allowed' : 'bg-ag-default'}`}
          >
            Save
          </button>
        </div>
      </section>
    </>
  );
})
export default UserEdit
