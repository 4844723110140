import { memo } from 'react';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  permissionsCategories,
  permissionsLookup
} from "../../hooks/useRoleCheck";
import { buildAddressString } from "../../utils/buildAddressString";


const SitePermissions = memo(({ 
  site,
  onClick,
  expanded,
  setExpanded,
  userPermissions,
  setUserPermissions,
  sitePermissions,
  index 
}) =>
{

  const sitePermission = userPermissions.find(x => Number(x.customerId) === Number(site.id));

  const actionUpdateExpanded = () => {
    setExpanded({ ...expanded,  [site.id]: !expanded[site.id] });
    onClick();
  };

  const actionUpdateSelected = (permission) => {

    const found = userPermissions.find(x => Number(x.customerId) === Number(site.id));

    const updated = { 
      ...found, 
      [permission?.realm]: !found[permission.realm] };

    const filtered = userPermissions.filter(x => Number(x.customerId) !== Number(site.id));

    setUserPermissions([ ...filtered, updated ]);

  };

  const HasActivePermissions = () => {

    if(!sitePermissions) return <XMarkIcon className="w-6 h-6" />;

    const permissions = Object.values(sitePermissions);

    if (permissions.includes(true)) {
      return <CheckIcon className="w-6 h-6 text-ag-lightGreen" />;
    }

    return <XMarkIcon className="w-6 h-6" />;
  }

  return (
    <div>
      <div
        onClick={() => actionUpdateExpanded()}
        className={`py-4 px-6 grid grid-cols-10 border-b select-none hover:bg-neutral-200 ${
          index % 2 === 0 && "bg-gray-100"
        }`}
      >
        <div className="col-span-1">
          <HasActivePermissions />
        </div>
        <div className="col-span-8">
          <span className="text-md text-ag-default font-medium">
            {site.name} - {buildAddressString(site.address1, site.address2)}
          </span>
        </div>
        <div
          className="flex justify-center"
        >
          {expanded[site.id] ? (
            <ChevronUpIcon className="w-6 h-6" />
          ) : (
            <ChevronDownIcon className="w-6 h-6" />
          )}
        </div>
      </div>
      {expanded[site.id] && (
        <div className="py-4 px-6 grid grid-cols-2 gap-y-4">
          {Object.keys(permissionsCategories).map((catKey, i) => {
            let category = permissionsCategories[catKey];
            let permissions = Object.values(permissionsLookup).filter(
              (perm) => perm?.category === catKey
            );
            return (
              <div key={`${catKey}-${i}`}>
                <span className="font-medium text-sm text-gray-600">
                  {category?.name}
                </span>
                {permissions
                  .map((permission) => (
                  <div key={`${catKey}-${permission.name}-${i}`} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
                      onChange={() => actionUpdateSelected(permission)}
                      checked={sitePermission[permission.realm]}
                    />
                    <span className="text-sm text-gray-600">
                      {permission.name}
                    </span>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
})
export default SitePermissions