export function checkPermissionSimilarity(permissions) {
  let isActiveTrueObjects = [];
  let isActiveFalseObjects = [];

  for (const permission of permissions) {
      if (permission.isActive) {
          isActiveTrueObjects.push(permission);
      } else {
          isActiveFalseObjects.push(permission);
      }
  }

  // Check if some objects have isActive true and some false
  if (isActiveTrueObjects.length > 0 && isActiveFalseObjects.length > 0) {
      const allValuesSame = (objects) => {
          if (objects.length === 0) return false;

          const firstPermission = objects[0];
          const keys = Object.keys(firstPermission).filter(key => key !== 'customerId' && key !== 'isActive');

          for (let i = 1; i < objects.length; i++) {
              const permission = objects[i];
              const valuesMatch = keys.every(key => firstPermission[key] === permission[key]);
              if (!valuesMatch) {
                  return false;
              }
          }
          return true;
      };

      // Check if all objects with isActive true have identical values
      if (allValuesSame(isActiveTrueObjects)) {
          return 1;
      } else {
          return 2;
      }
  }

  // If all objects have isActive true
  if (isActiveTrueObjects.length === permissions.length) {
      return 0;
  }

  return 0; 
}