import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import basicIncludesFilter from "../../utils/basicIncludesFIlter";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import LoadingWheel from "../shared/LoadingWheel";
import TablePaginationControls from "../shared/TablePaginationControls";
import SiteDropdown from "../shared/SiteDropdown";

import { useDeviceMeters } from "../../data/useDeviceMeters";
import { useEquipment } from "../../data/useEquipment";

import useNotification from "../../hooks/useNotifications";

import useCurrentUserContext from "../../contexts/UserContext";
import { buildAddressString } from "../../utils/buildAddressString";

import {
  InformationCircleIcon
} from "@heroicons/react/20/solid";
import SubmitButton from "../shared/SubmitButton";

const AddressCell = (info) => {
  const { currentUser } = useCurrentUserContext();
  let site = currentUser.user.sites.find(
    (s) => s.id === info.row.original.customerId
  );
  if (site) {
    return buildAddressString(
      site.address1,
      site.address2,
      site.address3,
      site.town,
      site.postcode
    );
  } else {
    return buildAddressString(
      info.row.original.address1,
      info.row.original.address2
    );
  }
};

function EquipmentTable({
  data,
  selectedDevices,
  setSelectedDevices,
  setFilterSite,
  setStep,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const getChecked = (serialNo) => {
    return selectedDevices.find((sd) => sd.serialNumber === serialNo);
  };

  const handleCheckToggle = (serialNo, desc) => {
    setSelectedDevices((oldDevices) => {
      if (oldDevices.find((od) => od.serialNumber === serialNo)) {
        return oldDevices.filter((od) => od.serialNumber !== serialNo);
      } else {
        return [...oldDevices, { serialNumber: serialNo, description: desc }];
      }
    });
  };

  const CheckboxCell = (info, getChecked) => {
    const val = info.getValue();

    return val !== "N/A" ? (
      <div className="text-center">
        <input
          className="rounded h-6 w-6 border text-gray-400 bg-gray-50 border-gray-400 focus:ring-0"
          name={`selected-${info.row.original.serialNumber}`}
          type="checkbox"
          checked={getChecked(info.row.original.serialNumber)}
          onChange={() =>
            handleCheckToggle(
              info.row.original.serialNumber,
              info.row.original.description
            )
          }
        />
      </div>
    ) : (
      <div className="text-center">N/A</div>
    );
  };

  const cols = [
    {
      id: "select",
      header: "",
      cell: (info) => CheckboxCell(info, getChecked),
    },
    {
      accessorKey: "serialNumber",
      header: "Serial",
    },
    {
      accessorKey: "description",
      header: "Device Name",
    },
    {
      accessorKey: "name",
      header: "Company Name",
    },
    {
      id: "address",
      header: "Address",
      cell: (info) => AddressCell(info),
    },
    {
      accessorKey: "location",
      header: "Onsite Location",
    },
  ];

  const table = useReactTable({
    data: data,
    columns: cols,
    filterFns: {
      fuzzy: basicIncludesFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: basicIncludesFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="relative mb-4 md:my-4">
        <div className="w-full grid grid-cols-1 md:flex md:items-center md:justify-between">
          <div className="w-full -mt-4 mb-4 md:m-0 md:w-96">
            <div className="pointer-events-none relative md:absolute inset-y-8 md:inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 border text-ag-default bg-gray-50 border-gray-400 p-2 focus:border-ag-darkBlue"
              placeholder="Search"
            />
          </div>
          <SiteDropdown
            onChange={(e) => setFilterSite(e.target.value)}
            className="w-full md:w-1/4"
          />
        </div>
      </div>
      <div className="block overflow-y-hidden overflow-x-auto">
        <table className="min-w-full divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b-2 border-gray-300">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, ri) => {
              let rowCells = row.getVisibleCells();
              return (
                <tr
                  key={row.id}
                  className={`${ri % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                >
                  {rowCells.map((cell, ci) => (
                    <td
                      key={cell.id}
                      width="auto"
                      className={`${
                        ci === 0 ? "px-3 py-1 align-middle" : "px-3 py-4"
                      } text-sm ${ci === 1 ? "pl-5 font-bold" : "font-medium"} ${
                        ci > 1 ? "align-text-top" : ""
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePaginationControls table={table} />
      <div className="mt-6">
        <button
          onClick={() => setStep(2)}
          className="mt-8 p-2 px-4 rounded-full bg-ag-default text-white hover:bg-ag-lightDefault"
        >
          Next step
        </button>
      </div>
    </>
  );
}

function MeterTypeRow({ register, meter, field, index, outerIndex, errors }) {
  return (
    <tr>
      <input
        {...register(`meterReadings[${outerIndex}].meter[${index}].serialNo`, {
          value: field.serialNo,
        })}
        type="hidden"
      />
      <td className="w-4/12 py-2 px-1">
        <input
          {...register(`meterReadings[${outerIndex}].meter[${index}].type`, {
            value: meter.type,
          })}
          type="hidden"
        />
        {meter.type}
      </td>
      <td className="w-4/12 py-2 px-1">
        <div className="flex flex-col items-center">
          <label className="mb-1 text-gray-500 text-sm">Current Reading</label>
          <input
            {...register(
              `meterReadings[${outerIndex}].meter[${index}].current`,
              {
                value: `${new Intl.NumberFormat("en-GB").format(meter.current)} ${(meter.readingTypeId === 3 ? 'E' : '')}`,
              }
            )}
            disabled
            className="border w-28 text-center text-gray-600 bg-gray-200 border-gray-400 px-2 py-1 focus:border-ag-darkBlue $`"
          />
        </div>
      </td>
      <td className="w-4/12 py-2 px-1">
        <div className="flex flex-col items-center">
          <label className="mb-1 text-gray-500 text-sm">New Reading</label>
          <input
            {...register(`meterReadings[${outerIndex}].meter[${index}].new`, {
              required: "Please enter a value",
              min: {
                value: meter.current,
                message: "Reading cannot be less than current",
              },
              pattern: {
                value: /^\d+$/,
                message: "Please enter only numbers",
              },
            })}
            type="number"
            aria-invalid={
              errors.meterReadings?.[outerIndex]?.meter[index]?.new
                ? "true"
                : "false"
            }
            className={`border w-28 text-center text-black bg-gray-50 border-gray-400 px-2 py-1 focus:border-ag-darkBlue ${
              errors.meterReadings?.[outerIndex]?.meter[index]?.new &&
              "border-red-600 focus:border-red-600 focus:outline-none focus:ring-0"
            }`}
          />
          {errors.meterReadings?.[outerIndex]?.meter[index]?.new && (
            <p role="alert" className="text-red-600">
              {errors.meterReadings?.[outerIndex]?.meter[index]?.new?.message}
            </p>
          )}
        </div>
      </td>
    </tr>
  );
}

function MeterRow({ index, field, register, errors, customer }) {
  const { meters } = useDeviceMeters(customer.id, field.serialNumber);

  return (
    <tr className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
      <td className="px-3 py-4 pl-5 text-sm font-bold">{field.serialNumber}</td>
      <td className="px-3 py-4 text-sm font-medium">{field.description}</td>
      <td className="px-3 text-sm font-medium">
        <table className="w-full">
          <tbody>
            {!meters.isLoading ? (
              meters.data.meters.length > 0 ? (
                meters.data.meters.map((meter, i) => (
                  <MeterTypeRow
                    index={i}
                    outerIndex={index}
                    field={field}
                    meter={meter}
                    errors={errors}
                    register={register}
                    key={`meterRow-${i}`}
                  />
                ))
              ) : (
                <tr>
                  <td className="text-gray-500">
                    No current readings, please call 0208 688 4243 (opt 2)
                  </td>
                </tr>
              )
            ) : (
              <LoadingWheel />
            )}
          </tbody>
        </table>
      </td>
    </tr>
  );
}

function MeterTable({ customer, equipment, onSubmit, setStep, formSubmitting }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ reValidateMode: "onBlur" });

  const submit = (data) => {
    let flatReading = [];

    //Flatten readings for posting to API
    data.meterReadings.forEach((outerEl) => {
      if (outerEl.meter) {
        outerEl.meter.forEach((el) => {
          flatReading.push({
            current: parseInt(el.current.replace(",", "")),
            new: parseInt(el.new),
            serialNo: el.serialNo,
            type: el.type,
          });
        });
      }
    });

    onSubmit({ meterReadings: flatReading });
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <table className="min-w-full divide-y border divide-gray-300">
          <thead>
            <tr className="border-b-2 border-gray-300">
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Serial Number
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Device Name
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Meters
              </th>
            </tr>
          </thead>
          <tbody>
            {equipment.map((field, i) => (
              <MeterRow
                index={i}
                field={field}
                register={register}
                errors={errors}
                customer={customer}
              />
            ))}
          </tbody>
        </table>
        <div className="mt-10 mb-5 flex items-center space-x-4">
          <button
            onClick={() => setStep(1)}
            className="p-2 px-4 rounded-full bg-white text-ag-default border border-ag-default hover:text-ag-lightDefault hover:border-ag-lightDefault"
          >
            Back
          </button>
          <SubmitButton text="Submit Request" submitting={formSubmitting} />
        </div>
      </form>
    </>
  );
}

export default function SubmitMeterReadings({ customer }) {
  const { currentUser } = useCurrentUserContext();
  const { equipment: allEquipment, submitReadings } = useEquipment(customer.id);
  const { addNotification } = useNotification();
  const [step, setStep] = useState(1);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [filterSite, setFilterSite] = useState("*");
  const [equipment, setEquipment] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    if (filterSite !== "*" && !allEquipment.isLoading) {
      setEquipment(
        allEquipment.data.equipment.filter(
          (e) => e.customerId === parseInt(filterSite)
        )
      );
    } else if (!allEquipment.isLoading) {
      setEquipment(allEquipment.data.equipment);
    }
  }, [filterSite, allEquipment.data, allEquipment.isLoading]);

  const handleSubmit = (readings) => {
    setFormSubmitting(true);

    submitReadings.mutate(
      {
        yourName: `${currentUser.user.firstname} ${currentUser.user.lastname}`,
        emailAddress: currentUser.user.emailAddress,
        customer: customer.name,
        ...readings,
        telephone: currentUser.user.telephone,
        department: currentUser.user.department,
        company: currentUser.customer.name
      },
      {
        onSuccess: () => {
          addNotification({
            variant: "success",
            primaryText: "Meter Readings Submitted",
            secondaryText: "Your new meter readings have been submitted",
          });
          setStep(1);
          setSelectedDevices([]);
          setFormSubmitting(false);
        },
        onError: (error) => {
          console.error("Error in requestConsumables:", error);
          addNotification({
            variant: "error",
            primaryText: "Error submitting meter readings",
            secondaryText: error.message,
          });
          setFormSubmitting(false);
        },
      }
    );
  };

  return (
    <div className="min-h-[55vh]">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-start">
          <h1 className="text-xl font-medium text-ag-default">
            Submit Meter Readings
          </h1>
        </div>
        {allEquipment.isLoading && !allEquipment.data?.equipment ? (
          <div className="flex items-center">
            <LoadingWheel />
          </div>
        ) : step === 1 ? (
          <div className="mt-4">
            <div className="mt-8">
              <EquipmentTable
                data={equipment.sort((a, b) => {
                  const accountNumberA = a.accountNumber;
                  const accountNumberB = b.accountNumber;

                  if (accountNumberA < accountNumberB) {
                    return -1;
                  }
                  if (accountNumberA > accountNumberB) {
                    return 1;
                  }
                  return 0;
                })}
                selectedDevices={selectedDevices}
                setSelectedDevices={setSelectedDevices}
                setFilterSite={setFilterSite}
                setStep={setStep}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="rounded-md bg-red-50 my-4 p-4 w-full">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-red-700">
                    Where a reading ends with an E e.g. 12345 E this indicates the reading is an estimate, if your actual reading is significantly lower than the estimate please contact{"  "}
                    <a className="text-red-900" href="mailto: meterreadings@agilico.co.uk">
                      meterreadings@agilico.co.uk
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <MeterTable
              customer={customer}
              equipment={selectedDevices}
              onSubmit={handleSubmit}
              setStep={setStep}
              formSubmitting={formSubmitting}
            />
          </div>
        )}
      </div>
    </div>
  );
}